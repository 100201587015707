import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const formatCurrency = (n) => {
    // Format currency id-ID
    // Format currency ph-PH
    const format = new Intl.NumberFormat('ph-PH', {
        style: 'currency',
        currency: 'PHP'        
    });
  
    // or use toLocaleString()
    return format.format(n);
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const calculateTotal = (arr) => {
    if (!arr || arr?.length === 0) return 0;
  
    const total = arr.reduce((acc, val) => acc + val, 0);
  
    return total.toFixed(2);
}

export const formatCurrencyKStyle = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num)
}

export const actionMessage = (message, status = 'info') => {
    toast(message, {
        type: status,
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });

    <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export function escapeStringForJson(string) {
    // Replace all special characters with their escaped counterparts.
    return string.replace(/\\/g, "\\\\").replace(/"/g, "\\\"").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\f/g, "\\f");
  }

export function nowTime() {
    let date = new Date(Date.now())
    let timeLocale = date.toLocaleTimeString('id-ID', {hour:'2-digit', minute:'2-digit', second:'2-digit', hour12:false})
    let formattedTime = timeLocale.replace(/\./g, ':');
    return formattedTime
}

export function startEndShowCategory(category) {
    let isStartBigger = category.start_show > category.end_show
    if(isStartBigger) {
        if(nowTime() > category.end_show) {
           return ((category.start_show < nowTime()) && (nowTime() > category.end_show))
        }
        else if(nowTime() < category.end_show) {
            return ((category.start_show > nowTime()) && (nowTime() < category.end_show))
        }
    }
    else {
        return (category.start_show < nowTime() && nowTime() < category.end_show)
    }
}

export function categoryItemShowFilter({category}) {
    return (category.start_show == null && category.end_show == null) || (startEndShowCategory(category))
}

export function categoryItemIsShowIsNotAvailable({category}) {
    let isStartBigger = category.start_show > category.end_show
    
    if(isStartBigger) {
        return (category.start_show > nowTime() && nowTime() > category.end_show)
    }
    else {
        return ((category.start_show > nowTime() && nowTime() < category.end_show)||( category.start_show < nowTime && nowTime > category.end_show))
    }
    
    // return (((category.start_show < nowTime()) && (nowTime() > category.end_show)) || ((category.start_show > nowTime()) && (nowTime() < category.end_show)))
}